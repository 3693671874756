//crear un componente que permita buscar un lugar en el mapa
//debe tener un input y un boton
//el input debe tener un placeholder que diga "Buscar lugar"
//el boton debe ser una lupita de mui icons
//cuando se haga click en el boton se abre un dialogo con un input y un boton

import SearchIcon from '@mui/icons-material/Search';
import { Button, Dialog, DialogActions, DialogContent, TextField,  List, ListItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState,useEffect } from 'react';
import { useContext } from 'react';
import { MapContext } from '../../context/MapContextProvider';
import axios from 'axios';
import { fromLonLat } from 'ol/proj';
import './MapTools.scss';
import _ from 'lodash';

const SearcherComponent = () => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const { map } = useContext(MapContext);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


  // Función para obtener sugerencias de la API
  const fetchSuggestions = async (query) => {
    const apiKey = process.env.REACT_APP_API_BING_MAPS;
    try {
        const response = await axios.get(`https://dev.virtualearth.net/REST/v1/Autosuggest?query=${query}&key=${apiKey}`);
        const suggestions = response.data.resourceSets[0].resources[0].value;
        setSuggestions(suggestions.map(s => s.address.formattedAddress)); // Ajusta según la estructura de respuesta de tu API
    } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
    }
};

  // Debounce para limitar las llamadas a la API mientras el usuario escribe
  const debouncedFetchSuggestions = _.debounce(fetchSuggestions, 300);

  useEffect(() => {
      if (search) {
          debouncedFetchSuggestions(search);
      } else {
          // Cancela cualquier invocación pendiente de debouncedFetchSuggestions
          debouncedFetchSuggestions.cancel();
          setSuggestions([]);
      }
  }, [search]);

  const handleSearch = async () => {
    const apiKey = process.env.REACT_APP_API_BING_MAPS;
    try {
        const response = await axios.get(`https://dev.virtualearth.net/REST/v1/Locations?q=${search}&key=${apiKey}`);
        const result = response.data.resourceSets[0].resources[0];
        if (result) {
            const location = {
                name: result.name,
                coordinates: [result.point.coordinates[1], result.point.coordinates[0]] // [lat, lon]
            };

            const view = map.getView();
            view.setCenter(fromLonLat(location.coordinates));
            view.setZoom(18); // Ajusta el nivel de zoom según sea necesario

            setOpen(false);
        }
    } catch (error) {
        console.error('Error al buscar la ubicación:', error);
    }
};

    return (
        <div className='tools'>
            <Button title="Search button" onClick={handleOpen} className='search-button'>
                <SearchIcon />
            </Button>
            <Dialog open={open} onClose={handleClose}>
            <form onSubmit={(e) => {
                e.preventDefault(); // Previene el comportamiento por defecto del formulario
                handleSearch(); // Llama a la función de búsqueda
            }}>
                <DialogContent>
                    <TextField 
                        label="Search a place" 
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} 
                        autoFocus
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={() => setSearch('')}
                                    edge="end"
                                >
                                    <CloseIcon />
                                </IconButton>
                            ),
                        }}
                    />
                    <List>
                        {suggestions.map((suggestion, index) => (
                            <ListItem key={index} button onClick={() => {
                                setSearch(suggestion);
                                setSuggestions([]);
                            }}>
                                {suggestion}
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={handleClose}>Close</Button>
                    <Button type="submit">Search</Button> {/* Cambia este botón a type="submit" */}
                </DialogActions>
            </form>
            </Dialog>
        </div>
    );
}

export default SearcherComponent;