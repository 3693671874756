/* eslint-disable-next-line  no-unused-vars */
import React, { createElement } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

export default function ModalDialog(props) {
    const {
        openModal,
        setOpenModal,
        dialogTitle,
        dialogContent,
        onSubmit,
        onCancel,
        submitButtonText,
        cancelButtonText
    } = props;

    const handleDialogOk = () => {
        if(onSubmit) onSubmit(openModal);
        setOpenModal(false);
    };

    const handleDialogClose = () => {
        setOpenModal(false);
        if(onCancel) onCancel();
    };

    const getContent = () => {
        return dialogContent ? (
            dialogContent
        ) : (
            <>
                Item will be deleted. <br /> Are you sure ?{' '}
            </>
        );
    };

    const getDialog = () => {
        return (
            <Dialog
                open={openModal ? true : false}
                keepMounted
                onClose={handleDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{dialogTitle ? dialogTitle : 'Delete Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{getContent()}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogOk}>{submitButtonText ? submitButtonText : 'Delete'}</Button>
                    <Button onClick={handleDialogClose}>{cancelButtonText ? cancelButtonText : 'Cancel'}</Button>
                </DialogActions>
            </Dialog>
        );
    };

    return <>{getDialog()}</>;
}

ModalDialog.propTypes = {
    openModal: PropTypes.bool.isRequired,
    setOpenModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.object,
    submitButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string
};
