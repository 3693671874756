import { useContext} from "react";
import { AppContext } from "../../context/AppContextProvider";
import AnalysisComponent from "../DrawComponent/AnalysisComponent";
import DrawComponent from "../DrawComponent/DrawComponent";
import GeoLocateComponent from "../GeoLocateComponent/GeoLocateComponent";
import LayerControl from "../LayerControl/LayerControl";
import SearcherComponent from "./SearcherComponent";
import NamePointComponent from "./NamePointComponent";
import "./MapTools.scss";

const MapTools = ({ userMail }) => {
    const { selectedMenu, isActiveTool, selectedProjectId, activeLayerId  } = useContext(AppContext);

    
    return (
        <div className="overlay-tools">

            <GeoLocateComponent />
            <SearcherComponent />   
            <NamePointComponent userMail={userMail}/>
            {
                // selectedMenu === "analysis" ? (
                selectedProjectId!= null || selectedMenu === 'analysis'?(
                    <>
                        <LayerControl />
                        <AnalysisComponent />
                        {/* <SwipeComponent /> */}
                    </>
                ) : selectedMenu === "order" && isActiveTool === "draw" ? (
                        <DrawComponent />
                ) : null
            }
            
        </div>
    );
}

export default MapTools;