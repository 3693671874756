import React, { useState } from "react";
import "./LoginMobile.scss";
import { useAlert } from "../../components/Alert/useAlert";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const LoginComponentMobile = ({ loginAction, setUserId, onShowPrivacyPolicy }) => {
    const showAlert = useAlert();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [completeName, setCompleteName] = useState("");
    const [error, setError] = useState("");
    const [isLogin, setIsLogin] = useState(true);
    const [invitationtoken, setInvitationToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorRegister, setErrorRegister] = useState("");
    const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false);

    const onChangeUserName = (val) => setUserName(val.target.value);
    const onChangePassword = (val) => setPassword(val.target.value);
    const onChangeConfirmPassword = (val) => setConfirmPassword(val.target.value);
    const onChangeCompleteName = (val) => setCompleteName(val.target.value);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
    //const API_BASE_URL = "https://localhost:7284";


    const onChangeInvitationToken = (val) => {
        setInvitationToken(val.target.value);
    }

    //Inicio implementación ChangePassword. 
      
      const [open, setOpen] = useState(false);
      const [email, setEmail] = useState('');
      const [token, setToken] = useState('');
      const [newPassword, setNewPassword] = useState('');
      const [tokenVerified, setTokenVerified] = useState(false);
      const [emailSent, setEmailSent] = useState(false);
      const [success, setSuccess] = useState(false);
      const [resetEmail, setResetEmail] = useState('');
      const [showResetPassword, setShowResetPassword] = useState(false);

      const handleRequestResetToken = async () => {
        setLoading(true);
        setError('');
        setSuccess(false);
      
        try {
          const response = await fetch(`${API_BASE_URL}/api/ResetPassword/request-reset`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(resetEmail.replace(/"/g, '')),
          });
      
          setLoading(false);
          if (response.ok) {
            setEmailSent(true);
          } else {
            const errorData = await response.json();
            setError(errorData.message || 'Failed to send reset email.');
          }
        } catch (error) {
          setLoading(false);
          console.error("Error connecting to server:", error);
          setError('Failed to connect to server.');
        }
      };
      
      const handleVerifyToken = async () => {
        setLoading(true);
        setError('');
        setSuccess(false);
      
        try {
          const response = await fetch(`${API_BASE_URL}/api/ResetPassword/confirm-reset?token=${encodeURIComponent(token)}`, {
            method: 'GET',
          });
      
          setLoading(false);
          if (response.ok) {
            setTokenVerified(true);
          } else {
            const errorText = await response.text();
            setError(errorText || 'Invalid or expired token verify.');
          }
        } catch (error) {
          setLoading(false);
          console.error("Error connecting to server:", error);
          setError('Failed to connect to server.');
        }
      };
      
      const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
          setError("Passwords do not match.");
          return;
        }
      
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
        if (!passwordRegex.test(newPassword)) {
          setError("La contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula, un número y un carácter especial.");
          return;
        }
      
        setLoading(true);
        setError('');
        setSuccess(false);
      
        try {
          const response = await fetch(`${API_BASE_URL}/api/ResetPassword/confirm-reset`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, newPassword }), 
          });
      
          setLoading(false);
          if (response.ok) {
            setSuccess(true);
          } else {
            const errorData = await response.json();
            setError(errorData.message || 'Password reset failed.');
          }
        } catch (error) {
          setLoading(false);
          setError('Failed to connect to server.');
        }
      };
      //Fin implementación ChangePassword
    



    const login = async (userName, password) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/Auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userName, password }),
            });

            if (response.ok) {
                const data = await response.json();
                showAlert('Successfully Login the System', 'success');
                loginAction(data);
            } else {
                setError("Wrong email or password.");
            }
        } catch (error) {
            showAlert('Could not connect to server', 'error');
            console.error("Error fetching data:", error);
        }
    };

    const register = async () => {
        if (!agreePrivacyPolicy) {
          setErrorRegister("You must agree to the privacy policy to register.");
          return;
        }
      
        if (password !== confirmPassword) {
          setErrorRegister("Passwords do not match.");
          return;
        }
      
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
        if (!passwordRegex.test(password)) {
          setErrorRegister("La contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula, un número y un carácter especial.");
          return;
        }
      
        try {
          setLoading(true);
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_AWS}/api/Auth/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                email: userName, 
                password, 
                confirmPassword, 
                completeName, 
                invitationToken: invitationtoken, 
                agreePrivacyPolicy: agreePrivacyPolicy             
            }),
          });
      
          setLoading(false);
      
          if (response.ok) {
            showAlert('Successfully Registered', 'success');
            setIsLogin(true);
          } else {
            const errorData = await response.json();
            setErrorRegister(errorData.message || "Registration failed.");
          }
        } catch (error) {
          setLoading(false);
          showAlert('Could not connect to server', 'error');
          console.error("Error fetching data:", error);
        }
      };

    const signIn = () => login(userName, password);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            signIn();
        }
    };

    return (
        <div className="mobile-login-container">
            <div className={`mobile-form-container ${isLogin ? 'login-view' : 'register-view'}`}>
                <div className="form-slider">
                    <div className="mobile-login-form login-form">
                        <h1 className="login-h1">Login</h1>
                        <input
                            className="login-input"
                            type="email"
                            placeholder="Email"
                            value={userName}
                            onChange={onChangeUserName}
                        />
                        <input
                            className="login-input"
                            type="password"
                            placeholder="password"
                            value={password}
                            onKeyDown={handleKeyDown}
                            onChange={onChangePassword}
                        />
                        {error ==='Wrong email or password.' && <Alert severity="error" style={{ marginBottom: '5px' }}>{error}</Alert>}
                        <a className="login-link" href="#" onClick={() => setShowResetPassword(true)}>Forgot your password?</a>
                        <button className="login-btn" onClick={signIn}>Login</button>
                        <p className="login-p">
                            Don't have an account? <span className="login-link" onClick={() => setIsLogin(false)}>Register</span>
                        </p>
                    </div>
                    <div className="mobile-register-form login-form">
                        <h1 className="login-h1">Create an Account</h1>
                        <input
                            className="login-input"
                            type="text"
                            placeholder="Name"
                            value={completeName}
                            onChange={onChangeCompleteName}
                        />
                        <input
                            className="login-input"
                            type="email"
                            placeholder="Email"
                            value={userName}
                            onChange={onChangeUserName}
                        />
                        <input
                            className="login-input"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={onChangePassword}
                        />
                        <input
                            className="login-input"
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={onChangeConfirmPassword}
                        />
                         <input
                            className="login-input"
                            type=""
                            placeholder="Token"
                            value={invitationtoken}
                            onChange={onChangeInvitationToken}
                        />
                        <FormControlLabel
                        control={<Checkbox checked={agreePrivacyPolicy} onChange={() => setAgreePrivacyPolicy(!agreePrivacyPolicy)} />}
                        label="I have read and accept the privacy policy"
                        />
                        <button type="button" onClick={() => {onShowPrivacyPolicy(); }} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', fontSize: 15 }}>
                             Privacy Policy
                        </button>
                           {errorRegister && <Alert severity="error" style={{ marginBottom: '5px' }}>{errorRegister}</Alert>}
                        
                        {loading ? (
                                <CircularProgress /> // Indicador de carga
                            ) : (
                                <button className="login-btn" onClick={register}>Register</button>
                         )}
                        <p className="login-p">
                            Already have an account? <span className="login-link" onClick={() => setIsLogin(true)}>Sign in</span>
                        </p>
                    </div>
                </div>
            </div>
            <footer>
                <a href="https://www.sanzar-group.com/" target="_blank">Sanzar Group</a>
            </footer>
            <Modal
        open={showResetPassword}
        onClose={() => setShowResetPassword(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {emailSent ? (tokenVerified ? "Change Password" : "Verify Token") : "Request Password Reset"}
          </Typography>
          {!emailSent ? (
            <>
              <TextField
                label="Email"
                fullWidth
                margin="normal"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
              {error && <Alert severity="error">{error}</Alert>}
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" fullWidth onClick={handleRequestResetToken}>
                  Send Reset Email
                </Button>
              )}
            </>
          ) : !tokenVerified ? (
            <>
              <TextField
                label="Token"
                fullWidth
                margin="normal"
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              {error && <Alert severity="error">{error}</Alert>}
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" fullWidth onClick={handleVerifyToken}>
                  Verify Token
                </Button>
              )}
            </>
          ) : (
            <>
              <TextField
                label="New Password"
                type="password"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                label="Confirm New Password"
                type="password"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {error && <Alert severity="error">{error}</Alert>}
              {success && <Alert severity="success">Password changed successfully!</Alert>}
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" fullWidth onClick={handleChangePassword}>
                  Change Password
                </Button>
              )}
            </>
          )}
        </Box>
      </Modal>
        </div>
    );
};

export default LoginComponentMobile;