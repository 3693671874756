import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import PrivacyPolicyPage from './PrivacyPolicyPage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '80vh',
};

const PrivacyPolicyModal = ({ show, onClose }) => {
  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Privacy Policy
        </Typography>
        <PrivacyPolicyPage />
        <Button onClick={onClose} style={{ marginTop: '16px' }}>Close</Button>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicyModal;
