import { createContext, useEffect, useState, useCallback } from 'react';
// Crea el contexto y exporta para que otros componentes puedan consumirlo
export const AppContext = createContext();

export default function AppContextProvider({ isLoggedIn, userId, setUserId, selectedProjectId, setSelectedProjectId, layersFromProject, setLayersFromProject, userName,  children }) {

    const [selectedMenu, setSelectedMenu] = useState();
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isActiveTool, setIsActiveTool] = useState(false);
   // const [logout, setLogout] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [activeLayerId, setActiveLayerId]= useState('');

    const [projectsUpdateToggle, setProjectsUpdateToggle] = useState(false);

    // Función para toggle el estado
    const toggleProjectsUpdate = useCallback(() => {
        setProjectsUpdateToggle(prev => !prev); // Cambia el estado para forzar una actualización
    }, []);

    useEffect(() => {
        setLoggedIn(isLoggedIn);
    }, [isLoggedIn]);

    return (
        <AppContext.Provider
            value={{
                selectedMenu,
                setSelectedMenu,
                isMenuActive,
                setIsMenuActive,
                loggedIn,
                setLoggedIn,
                userId,
                selectedProjectId,
                setSelectedProjectId,
                layersFromProject,
                setLayersFromProject,
                isActiveTool,
                setIsActiveTool,
                projectsUpdateToggle,
                toggleProjectsUpdate,
                activeLayerId,
                setActiveLayerId,
                userName,
                setUserId
            }}
        >
            { loggedIn ? children: <></>}
        </AppContext.Provider>
    );
}
