import React from 'react';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import "./PrivacyPolicyPage.scss"; // Asegúrate de tener el archivo de estilos

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(4), // Ajusta el valor según la cantidad de sangría que desees
    '& ul, & ol': {
        marginLeft: theme.spacing(2), // Doble del padding del contenido
    },
}));

export default function PrivacyPolicyPage() {
    return (
        <div className="privacy-policy-container">
            <div className="privacy-policy-content">
                <Typography variant="h4" gutterBottom>
                    Política de Privacidad "AI Land Software"
                </Typography>
                <Typography gutterBottom>
                    <b>Fecha de última actualización: 12 de julio del 2024.</b>
                </Typography>
                <Typography gutterBottom>
                    De conformidad con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, 
                    relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la 
                    libre circulación de estos datos (Reglamento General de Protección de Datos – RGPD), "Agroaerospace S.L." 
                    informa a los usuarios de la aplicación web "AI Land Software" (en adelante, la Aplicación), acerca del tratamiento de los 
                    datos personales que ellos voluntariamente hayan facilitado durante el proceso de registro, acceso y utilización del servicio.
                </Typography>
                <Typography gutterBottom>
                    <b>1. Identificación del Responsable del Tratamiento</b>
                </Typography>
                <Typography gutterBottom>
                    <b>Identidad:</b> Agroaerospace S.L.<br/>
                    <b>CIF/NIF:</b> B88360565<br/>
                    <b>Domicilio:</b> Avenida Gregorio Peces Barba, 1. Leganés<br/>
                    <b>Email:</b> info@sanzar-group.com
                </Typography>
                <Typography gutterBottom>
                    <b>2. Finalidad del Tratamiento de Datos</b>
                </Typography>
                <Typography gutterBottom>
                    Para proceder al registro, acceso y posterior uso de la Aplicación, el Usuario deberá facilitar -de forma voluntaria- datos de carácter personal, incluyendo:
                </Typography>
                <Typography component="ul" gutterBottom>
                    <li>Nombre o seudónimo</li>
                    <li>Correo electrónico</li>
                    <li>Token proporcionado por la empresa</li>
                    <li>Contraseña</li>
                </Typography>
                <Typography gutterBottom>
                    Estos datos serán incorporados a soportes automatizados titularidad de "Agroaerospace S.L." con las siguientes finalidades:
                </Typography>
                <Typography component="ul" gutterBottom>
                    <li>Garantizar el correcto funcionamiento de la Aplicación.</li>
                    <li>Mantener la relación de prestación de servicios y/o comercial con el Usuario.</li>
                    <li>Gestión, administración, información, prestación y mejora del servicio.</li>
                    <li>Enviar boletines (newsletters) y comunicaciones comerciales de promociones y/o publicidad de la Aplicación, siempre que el Usuario haya prestado previamente su consentimiento expreso para la recepción de estas comunicaciones vía electrónica.</li>
                </Typography>
                <Typography gutterBottom>
                    <b>3. Legitimación</b>
                </Typography>
                <Typography gutterBottom>
                    El tratamiento de los datos del Usuario se realiza con las siguientes bases jurídicas que legitiman el mismo:
                </Typography>
                <Typography component="ul" gutterBottom>
                    <li>Consentimiento libre, específico, informado e inequívoco del Usuario, quien deberá aceptar esta política de privacidad mediante una declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al efecto.</li>
                    <li>Solicitud de información y/o contratación de los servicios de la Aplicación, cuyos términos y condiciones se pondrán a disposición del Usuario para su expresa aceptación.</li>
                </Typography>
                <Typography gutterBottom>
                    <b>4. Conservación de los Datos Personales</b>
                </Typography>
                <Typography gutterBottom>
                    Los datos personales proporcionados por el Usuario se conservarán en los sistemas y bases de datos de "Agroaerospace S.L." mientras aquel continúe haciendo uso de la Aplicación y no solicite su supresión.
                </Typography>
                <Typography gutterBottom>
                    Con el objetivo de depurar las posibles responsabilidades derivadas del tratamiento, los datos se conservarán por un período mínimo de cinco años.
                </Typography>
                <Typography gutterBottom>
                    <b>5. Destinatarios</b>
                </Typography>
                <Typography gutterBottom>
                    Los datos no se comunicarán a ningún tercero ajeno a "Agroaerospace S.L.", salvo obligación legal o previa solicitud del consentimiento del Usuario.
                </Typography>
                <Typography gutterBottom>
                    "Agroaerospace S.L." podrá dar acceso o transmitir los datos personales facilitados por el Usuario a terceros proveedores de servicios con los que haya suscrito acuerdos de encargo de tratamiento de datos, y que únicamente accedan a dicha información para prestar un servicio en favor y por cuenta del Responsable.
                </Typography>
                <Typography gutterBottom>
                    <b>6. Transferencias Internacionales de Datos</b>
                </Typography>
                <Typography gutterBottom>
                    En caso de realizarse transferencias de datos personales fuera del Espacio Económico Europeo (EEE), "Agroaerospace S.L." garantizará que dichas transferencias se realicen conforme a las normativas vigentes y adopte las medidas adecuadas para proteger la información.
                </Typography>
                <Typography gutterBottom>
                    <b>7. Protección de la Información</b>
                </Typography>
                <Typography gutterBottom>
                    "Agroaerospace S.L." adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo dispuesto en el RGPD. Realizamos copias de seguridad de los contenidos alojados en nuestros servidores, pero no nos responsabilizamos de la pérdida o el borrado accidental de los datos por parte de los Usuarios.
                </Typography>
                <Typography gutterBottom>
                    <b>8. Ejercicio de Derechos</b>
                </Typography>
                <Typography gutterBottom>
                    El Usuario tiene derecho a:
                </Typography>
                <Typography component="ul" gutterBottom>
                    <li>Acceso: Conocer qué datos personales tratamos.</li>
                    <li>Rectificación: Solicitar la corrección de datos inexactos.</li>
                    <li>Supresión: Pedir la eliminación de sus datos cuando ya no sean necesarios para los fines recogidos.</li>
                    <li>Oposición: Oponerse al tratamiento de sus datos personales.</li>
                    <li>Limitación: Solicitar la limitación del tratamiento de sus datos en ciertos casos.</li>
                    <li>Portabilidad: Recibir sus datos en un formato estructurado, de uso común y lectura mecánica.</li>
                </Typography>
                <Typography gutterBottom>
                    Para ejercer estos derechos, el Usuario puede enviar una solicitud al correo electrónico: <a href="mailto:info@sanzar-group.com">info@sanzar-group.com</a>.
                </Typography>
                <Typography gutterBottom>
                    Asimismo, el Usuario tiene derecho a revocar el consentimiento inicialmente prestado y a interponer reclamaciones ante la Agencia Española de Protección de Datos (AEPD).
                </Typography>
                <Typography gutterBottom>
                    <b>9. Comunicaciones Comerciales por Vía Electrónica</b>
                </Typography>
                <Typography gutterBottom>
                    En aplicación de la LSSI (Ley de Servicios de la Sociedad de la Información), "Agroaerospace S.L." no enviará comunicaciones publicitarias o promocionales por correo electrónico u otro medio de comunicación electrónica equivalente que previamente no hubieran sido solicitadas o expresamente autorizadas por los destinatarios de las mismas.
                </Typography>
                <Typography gutterBottom>
                    En el caso de usuarios con los que exista una relación contractual previa, "Agroaerospace S.L." está autorizada al envío de comunicaciones comerciales referentes a productos o servicios similares a los inicialmente contratados. El Usuario puede darse de baja en cualquier momento enviando un correo electrónico a: <a href="mailto:info@sanzar-group.com">info@sanzar-group.com</a>.
                </Typography>
                <Typography gutterBottom>
                    <b>Actualizaciones y Modificaciones</b>
                </Typography>
                <Typography gutterBottom>
                    "Agroaerospace S.L." se reserva el derecho de modificar y/o actualizar la información sobre protección de datos cuando sea necesario para el cumplimiento del RGPD. Si se produjera alguna modificación, el nuevo texto será publicado en esta página, donde podrá acceder a la política actual.
                </Typography>
                <Typography gutterBottom>
                    <b>Canal de Comunicación y Soporte</b>
                </Typography>
                <Typography gutterBottom>
                    Para cualquier duda sobre el tratamiento de sus datos personales o interpretación de nuestra política de privacidad, el Usuario puede comunicarse al correo electrónico: <a href="mailto:info@sanzar-group.com">info@sanzar-group.com</a>.
                </Typography>
                <Typography gutterBottom>
                    <b>Legislación y Jurisdicción</b>
                </Typography>
                <Typography gutterBottom>
                    Esta política de privacidad se rige por la legislación española. Para cualquier controversia que pudiera surgir en relación con esta política de privacidad, las partes se someterán a los juzgados y tribunales de Madrid.
                </Typography>
            </div>
        </div>
    );
}
