import { IconButton } from "@mui/material";
import "./LayerControl.scss";
import { Layers, LayersOutlined, PolylineOutlined } from "@mui/icons-material";
import { MapContext } from "../../context/MapContextProvider";
import { useContext, useEffect, useRef, useState } from "react";
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';

const LayerControl = () => {

    const { map } = useContext(MapContext);
    const layerRef = useRef();
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    useEffect(() => {

        var ctrl = new LayerSwitcher({
            // collapsed: false,
            mouseover: true,
            extent: true,
            target: layerRef.current
        });
        map.addControl(ctrl);
        ctrl.on('toggle', function (e) {
            console.log('Collapse layerswitcher', e.collapsed);
        });

    }, []);
    const layerControlClick = () => {

    }

    return (
        <div className="tools base-map-switcher" onClick={() => setIsMenuOpen(!isMenuOpen)}>

            <div ref={layerRef} className='base-map-switcher__menu' style={{ display: isMenuOpen ? "block" : "none" }}>

            </div>

            {/* <i class="bx bx-layer"></i> */}
            <Layers onClick={layerControlClick} />
        </div>
    );
}

export default LayerControl;