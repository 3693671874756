/* eslint-disable-next-line  no-unused-vars */
import React, { createElement, useEffect, useRef, useState, useContext } from 'react';
import './Analysis.scss';
import { MapContext } from "../../context/MapContextProvider";
import { AppContext } from '../../context/AppContextProvider';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { transformExtent } from 'ol/proj';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  }));


export default function AnalysisComponent({ }) {

    const { selectedMenu, setSelectedMenu, isMenuActive, userId, selectedProjectId, setSelectedProjectId, setUserId, layersFromProject, setLayersFromProject, projectsUpdateToggle  } = useContext(AppContext);
    const [expanded, setExpanded] = React.useState(false);
    const [projects, setProjects] = useState([]);
    const { map  } = useContext(MapContext);
    const [forceUpdate, setForceUpdate] = useState(false);


    //useEffect para obtener los proyectos. 
    useEffect (()=>{
        const fetchProjects  = async () => {
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;

            // Obtener userId del localStorage si no está definido
            const storedUserId = localStorage.getItem('authToken');
            if (!userId && storedUserId) {
                setUserId(storedUserId);
            }
            if (!storedUserId && !userId) {
                console.error("No userId found.");
                return;
            }

            try {
                const finalUserId = userId || storedUserId;
                const response = await fetch(`${API_BASE_URL}/api/projects/user?userId=${finalUserId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
               // console.log("url peticion proj desde analisiscomp:",`${API_BASE_URL}/api/projects/user?userId=${userId}` )
                if(response.ok){
                    const data = await response.json();
                    setProjects(data);//aqui asumimos que recibimos un array de proyectos. 
                    localStorage.setItem('projects', JSON.stringify(data));
                }else{
                    //console.error("Failed to fetch projects");
                    // Intentar recuperar los proyectos de localStorage
                    console.log("El usuario en la regarga de pagina es:", userId);
                    const savedProjects = localStorage.getItem('projects');
                    if (savedProjects) {
                        setProjects(JSON.parse(savedProjects));
                    }
                }
            } catch (error) {
                console.log("El usuario en la regarga de pagina es:", userId);
               // console.error("Error fetching projects:", error);
               // Intentar recuperar los proyectos de localStorage
                const savedProjects = localStorage.getItem('projects');
                if (savedProjects) {
                    setProjects(JSON.parse(savedProjects));
                }
            }
        };

        if(userId || localStorage.getItem('authToken')) fetchProjects();//esto nos asegura que solo se intenta cargar los proyectos si hay un userId.
    },[userId, projectsUpdateToggle ]);//Dependiencia para re-ejecutar este efecto si userId cambia. 



    //Actualiza el valor del projectselected.
    const handleProjectSelection = async (projectId) => {
        setSelectedProjectId(projectId);
        setForceUpdate(f => !f);//Forzar la actualización de los componentes que dependen de este estado.
    }

    //useEffect para obtner las capas de un proyecto y centrar el mapa en ellas. 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
                const response = await fetch(`${API_BASE_URL}/api/geoserverlayers/projectId?projectId=${selectedProjectId}`);
                if (response.ok) {
                    const layers = await response.json();
                    console.log("capas obtenidas dentro de useEffect:", layers);
                    setLayersFromProject(layers);
                    // Guardar las capas en localStorage con una clave única para el proyecto seleccionado
                    localStorage.setItem(`layers_${selectedProjectId}`, JSON.stringify(layers));
    
                    // Centrar el mapa usando las coordenadas de las capas obtenidas
                    if (layers.length > 0) {
                        const maxX = layers[0].maxX;
                        const minY = layers[0].minY;
                        const maxY = layers[0].maxY;
                        const minX = layers[0].minX;
                        const extent = transformExtent([minX, minY, maxX, maxY], 'EPSG:4326', 'EPSG:3857');
                        map.getView().fit(extent, {
                            padding: [10, 10, 10, 10], // Ajusta según sea necesario
                            duration: 1000,  // Duración de la animación
                            maxZoom: 16 
                        });
                    }
                } else {
                    console.error("Failed to fetch layers for project");
                    // Intentar recuperar las capas desde localStorage
                    const savedLayers = localStorage.getItem(`layers_${selectedProjectId}`);
                    if (savedLayers) {
                        const layers = JSON.parse(savedLayers);
                        setLayersFromProject(layers);
    
                        // Centrar el mapa usando las coordenadas de las capas guardadas en localStorage
                        if (layers.length > 0) {
                            const maxX = layers[0].maxX;
                            const minY = layers[0].minY;
                            const maxY = layers[0].maxY;
                            const minX = layers[0].minX;
                            const extent = transformExtent([minX, minY, maxX, maxY], 'EPSG:4326', 'EPSG:3857');
                            map.getView().fit(extent, {
                                padding: [10, 10, 10, 10], // Ajusta según sea necesario
                                duration: 1000,  // Duración de la animación
                                maxZoom: 16 
                            });
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching layers:", error);
                // Intentar recuperar las capas desde localStorage
                const savedLayers = localStorage.getItem(`layers_${selectedProjectId}`);
                if (savedLayers) {
                    const layers = JSON.parse(savedLayers);
                    setLayersFromProject(layers);
    
                    // Centrar el mapa usando las coordenadas de las capas guardadas en localStorage
                    if (layers.length > 0) {
                        const maxX = layers[0].maxX;
                        const minY = layers[0].minY;
                        const maxY = layers[0].maxY;
                        const minX = layers[0].minX;
                        const extent = transformExtent([minX, minY, maxX, maxY], 'EPSG:4326', 'EPSG:3857');
                        map.getView().fit(extent, {
                            padding: [10, 10, 10, 10], // Ajusta según sea necesario
                            duration: 1000,  // Duración de la animación
                            maxZoom: 16 
                        });
                    }
                }
            }
        };
    
        if (selectedProjectId) {
            fetchData();
        }
    }, [selectedProjectId, map, forceUpdate]);


    //console.log("USER ID from analisiscomponent:",userId);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const legendRef = useRef();

    const onCloseMenu = () => {
        setSelectedMenu(null);
    }

    //this code must be deleted, it is just for test
    const getCurrentDate = () => {
        const date = new Date(); // Obtiene la fecha actual
        const options = { year: 'numeric', month: 'long', day: 'numeric' }; // Opciones para formatear la fecha
        return date.toLocaleDateString('en-US', options); // Retorna la fecha formateada
    };

    return (
        <>
           <div className="container-window" ref={legendRef}
            style={{
                display: selectedMenu == "analysis" ? "block" : "none",
                left: isMenuActive ? "255px" : "85px"
            }}>
            <div className="container-caption">
                <div>
                    Your Projects
                </div>
                <div onClick={onCloseMenu}>
                    <CancelIcon />
                </div>

            </div>
            
            <div className="container-card">
                {projects.length > 0 ? (
                        projects.map((project,index) => (
                            <Card key={project.ProjectId || index} sx={{ maxWidth: 350, marginBottom: '16px' }}>
                                <CardHeader  title={project.projectName} subheader={new Date(project.creationDate).toLocaleDateString()} />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {/* <b>placed on:</b> {project.AreaId} Asumiendo que quieras mostrar el AreaId, ajusta según sea necesario */}
                                        <br />
                                        <b>Description:</b> {project.description}
                                        <br />
                                        {/* <b>ProjectId:</b>{project.projectId} */}
                                    </Typography>
                                </CardContent>
                                <CardActions disableSpacing>
                                    <ExpandMore
                                        expand={false} // Asegúrate de manejar este estado según sea necesario
                                        aria-label="show more"
                                        onClick={() => handleProjectSelection(project.projectId)}
                                    >
                                        <AssignmentIcon/>  
                                            <CustomTypography>
                                                Select project
                                            </CustomTypography>
                                    </ExpandMore>
                                </CardActions>
                            </Card>
                        ))
                    ) : (
                        <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: '20px', color: "#000"  }}>
                            Currently, there are no projects associated with your account. Begin by initiating a new project through the 'New Project' tab.
                        </Typography>
                    )}
            </div> 

</div>
</>
    );
}
