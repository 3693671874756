/* eslint-disable-next-line  no-unused-vars */
import React, { useEffect, createElement } from 'react';

import PropTypes from 'prop-types';
import './Loader.css';

export default function MapLoader(props) {
    const { status } = props;
    useEffect(() => {}, []);

    return (
        <div id="map-loader" hidden={!status}>
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

MapLoader.propTypes = {
    status: PropTypes.bool.isRequired
};
