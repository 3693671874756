/* eslint-disable-next-line  no-unused-vars */
import React, { useState, useEffect, createElement } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    Typography
} from '@mui/material';

export default function ReportDialog(props) {
    const { openDialog, onClickAction } = props;
    const [modelName, setModelName] = useState('');
    const [modelUrl, setModelUrl] = useState('');
    const [activateFileLoader, setActivateFileLoader] = useState(false);

    const handleLayerName = (evt) => {
        setModelName(evt.target.value);
    };

    const handlelayerUrl = (evt) => {
        setModelUrl(evt.target.value);
    };

    const onCloseFunc = () => {
        console.log('close');
        onClickAction();
    };

    const buttonOkClicked = async () => {
        onClickAction();
    };

    const handleEntering = () => { };


    return (
        <Dialog
            // maxWidth="md"
            TransitionProps={{ onEntering: handleEntering }}
            open={openDialog}
        >
            <DialogTitle>Report Menu</DialogTitle>
            <DialogContent>
            <Typography gutterBottom>
                    Please provide details about the issue you encountered.
                    Fill in the fields below and click "Report" to submit your report.
                    Our team will review and address it promptly.
                </Typography>
                <div className="load-file-container">
                    <div>
                        <div className="load-file-input">
                            <TextField
                                fullWidth
                                id="standard-helperText"
                                label="Caption"
                                // error={modelName?.length < 3 ? true : false}
                                variant="standard"
                                onChange={handleLayerName}
                                value={modelName}
                            />
                        </div>
                        <div className=" .load-file-input">
                            <TextField
                                fullWidth
                                id="standard-helperText"
                                label="Your Message"
                                variant="standard"
                                onChange={handlelayerUrl}
                                value={modelUrl}
                                // helperText="Please write your message here"
                                disabled={activateFileLoader}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={buttonOkClicked}>
                    Report
                </Button>
                <Button onClick={onCloseFunc}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

ReportDialog.propTypes = {
    openDialog: PropTypes.bool,
    onClickAction: PropTypes.func
};
