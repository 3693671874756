import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ChangePasswordPopup = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
  //const API_BASE_URL = "https://localhost:7284";

  useEffect(() => {
    if (!open) {
      // Resetear el estado cuando el modal se cierra
      setEmail('');
      setToken('');
      setNewPassword('');
      setConfirmPassword('');
      setError('');
      setLoading(false);
      setSuccess(false);
      setEmailSent(false);
      setTokenVerified(false);
    }
  }, [open]);

  const handleRequestResetToken = async () => {
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const response = await fetch(`${API_BASE_URL}/api/ResetPassword/request-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
       // body: JSON.stringify(email.replace(/"/g, '')), // Enviar el email como string sin formato JSON
        body: JSON.stringify(email),
      });

      setLoading(false);
      if (response.ok) {
        setEmailSent(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to send reset email.');
      }
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to server:", error);
      setError('Failed to connect to server.');
    }
  };

  const handleVerifyToken = async () => {
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const response = await fetch(`${API_BASE_URL}/api/ResetPassword/confirm-reset?token=${encodeURIComponent(token)}`, {
        method: 'GET',
      });

      setLoading(false);
      if (response.ok) {
        setTokenVerified(true);
      } else {
        const errorText = await response.text(); // Intentar leer el texto en lugar de JSON
        setError(errorText || 'Invalid or expired token verify.');
      }
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to server:", error);
      setError('Failed to connect to server.');
    }
  };
  

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Validar la nueva contraseña
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setError("La contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula, un número y un carácter especial.");
      return;
    }

    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const response = await fetch(`${API_BASE_URL}/api/ResetPassword/confirm-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }), // Enviar el token y la nueva contraseña en formato JSON
      });

      setLoading(false);
      if (response.ok) {
        setSuccess(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Password reset failed.');
      }
    } catch (error) {
      setLoading(false);
      setError('Failed to connect to server.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {emailSent ? (tokenVerified ? "Change Password" : "Verify Token") : "Request Password Reset"}
        </Typography>
        {!emailSent ? (
          <>
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && <Alert severity="error">{error}</Alert>}
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" fullWidth onClick={handleRequestResetToken}>
                Send Reset Email
              </Button>
            )}
          </>
        ) : !tokenVerified ? (
          <>
            <TextField
              label="Token"
              fullWidth
              margin="normal"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            {error && <Alert severity="error">{error}</Alert>}
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" fullWidth onClick={handleVerifyToken}>
                Verify Token
              </Button>
            )}
          </>
        ) : (
          <>
            <TextField
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              label="Confirm New Password"
              type="password"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">Password changed successfully!</Alert>}
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" fullWidth onClick={handleChangePassword}>
                Change Password
              </Button>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ChangePasswordPopup;
