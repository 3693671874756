import { useContext, useEffect, useRef, useState } from "react";
import "./StatusBar.scss";
import { MapContext } from "../../context/MapContextProvider";
import { MousePosition, ScaleLine } from 'ol/control';
import { createStringXY } from 'ol/coordinate';

const StatusBarComponent = () => {
    const { map } = useContext(MapContext);
    const coordRef = useRef();

    useEffect(() => {
        // map.on("moveend", () => {
        //     console.log(map);
        // })

        const mousePositionControl = new MousePosition({
            coordinateFormat: createStringXY(4),
            projection: 'EPSG:4326',
            className: 'custom-mouse-position',
            target: coordRef.current,
        });

        const scaleControl = new ScaleLine({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140,
          });

        map.controls.push(scaleControl)
        map.controls.push(mousePositionControl)

    }, []);

    return (
        <span className="coordinates" ref={coordRef}/>

        // <div className="status-bar-container">
        //     <div className="transbox">
        //         <div >
        //             {/* <span className="scale">
        //                 {perspectiveSizeInMeters["distance"]} {perspectiveSizeInMeters["unit"]}
        //             </span>
        //             <span className="epsg" onClick={handleOpenEpsg}>
        //                 {selectedEpsg} {icon}
        //                 <span className={`epsg-dropdown ${isOpenEpsg ? "active" : ""}`}>
        //                     <span className="epsg-dropdown-item" onClick={handleClick}>EPSG:4326</span>
        //                     <span className="epsg-dropdown-item" onClick={handleClick}>EPSG:3857</span>
        //                 </span>
        //             </span> */}
        //         </div>
        //     </div>
        // </div>
    );
}

export default StatusBarComponent;