import { useContext, useEffect, useState, useCallback,useMemo, useReducer } from 'react';
//import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import './_variables.scss';
import MapComponent from './components/map/MapComponent';// Componente de mapa principal
// import MapComponentL from './components/map/MapComponentL';
import MapLoader from './components/Loader/MapLoader';// Componente de carga para el mapa
import StatusBarComponent from './components/StatusBarComponent/StatusBarComponent';
import MapTools from './components/MapTools/MapTools';
import Navbar from './components/layout/NavBar';
import AppContextProvider, { AppContext } from './context/AppContextProvider';
import OrderComponent from './components/OrderComponent/OrderComponent';
import AnalysisComponent from './components/AnalysisComponent/AnalysisComponent';
import LoginComponent from './components/Login/Login';
import LoginMobile from './mobileComponents/LoginMobile/LoginMobile';
import { AlertProvider } from './components/Alert/useAlert';// Proveedor de contexto para alertas
import { Interaction } from 'ol/interaction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivacyPolicyModal from './components/HelpMenu/PrivacyPolicyModal';

function App() {

  const [scriptLoaded, setscriptLoaded] = useState(true);// Estado para el script cargado
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState(null);//Estado para obtener el userId desde el login.
  const [userName, setUserName] = useState("");//Estado para obtener el userName desde el login.
  const [userMail, setUserMail] = useState("");
  // const [userId, setUserId] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState(null);//Estado para obtener el projecto seleccionado. 
  const [layersFromProject, setLayersFromProject] = useState([]);
  const [wasOffline, setWasOffline] = useState(false);
//const [isAuthReady, setIsAuthReady] = useState(false); // Nuevo estado para manejar la preparación de la autenticación
//const [authFailed, setAuthFailed] = useState(false);
const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

   useEffect(() => {

   }, []);

//   useEffect(() => {
//     console.log(`Estado de autenticación: ${isLoggedIn}, UserID: ${userId}`);
// }, [isLoggedIn, userId]);

  // const callbackLoader = (resolve) => {
  //   console.log("loaded")
  //   setscriptLoaded(true);
  // }

 // Function to handle online/offline status
useEffect(() => {
  const goOnline = () => {
    if (wasOffline) {
      toast.success('Online. La página se recargará para actualizar los contenidos.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {window.location.reload();} 
      });
    }
    setWasOffline(false);
  };

  const goOffline = () => {
    setWasOffline(true);
    toast.error('No se detectó conexión a Internet. Estás offline.', {
      position: 'bottom-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  window.addEventListener('online', goOnline);
  window.addEventListener('offline', goOffline);

  // Limpieza al desmontar el componente
  return () => {
    window.removeEventListener('online', goOnline);
    window.removeEventListener('offline', goOffline);
  };
}, [wasOffline]);


  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const expirationTime = localStorage.getItem('authTokenExpiration');
        if (expirationTime && parseInt(expirationTime) > Date.now()) {
          setIsLoggedIn(true);
          setUserId(localStorage.getItem('userId'));
          setUserName(localStorage.getItem('userName'));
        } else {
          clearLocalStorage();
        }
      } catch (error) {
        console.error('Token decoding failed:', error);
        clearLocalStorage();
      }
    }
  }, []);

  const clearLocalStorage = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authTokenExpiration');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');

  };

const loginAction = useCallback(async (data) => {
 // console.log("Data received:", data);

  // Asegúrate de que `setUserId` se llama y luego `setIsLoggedIn`.
  setUserId(data.userId);  // Actualiza userId
  setUserName(data.userName);//Actualiza userName
  setUserMail(data.email);

  // Espera a que se complete la actualización del estado de `userId` antes de cambiar `isLoggedIn`
  await new Promise(resolve => setTimeout(resolve, 0));  // Este truco espera hasta el próximo ciclo del evento loop

  if (data.userId) {
      setIsLoggedIn(true);

      const expirationTime = Date.now() + 5 * 24 * 60 * 60 * 1000; // 5 días en milisegundos
      //const expirationTime = Date.now() + 60 * 1000;  // 1 minuto en milisegundos
      localStorage.setItem('authToken', 'loggedIn');
      localStorage.setItem('authToken', data.userId);
      localStorage.setItem('authTokenExpiration', expirationTime); 
      localStorage.setItem('userName', data.userName);
      //añade el id del usuario al localstorage dentro del authToken
      
      
      //console.log("User is now logged in with ID:", data.userId);
  } else {
      setIsLoggedIn(false);
      localStorage.removeItem('authToken');
      //console.log("Failed to log in or no user ID provided.");
  }
}, [setUserId, setIsLoggedIn]);


const loginComponent = useMemo(() => {
  if(isLoggedIn){
    return null;
  }
  return isMobile ? (
    <LoginMobile loginAction={loginAction} userId={userId} setUserId={setUserId} onShowPrivacyPolicy={() => setShowPrivacyPolicy(true)}/>
  ) : (
    <LoginComponent loginAction={loginAction} userId={userId} setUserId={setUserId} onShowPrivacyPolicy={() => setShowPrivacyPolicy(true)}/>
  );
}, [isLoggedIn, isMobile, loginAction, userId, setUserId]);


  return (
    <>
      <AlertProvider>
              {/* Renderiza el componente de inicio de sesi�n si no est� iniciado */}
              {/* {!isLoggedIn && <LoginComponent loginAction={loginAction} userId={userId} setUserId={setUserId}/>} */}
              {loginComponent}
        <AppContextProvider 
          isLoggedIn={isLoggedIn} 
          userId={userId} 
          setUserId={setUserId}
          userName={userName}
          selectedProjectId={selectedProjectId} 
          setSelectedProjectId={setSelectedProjectId}
         layersFromProject={layersFromProject} 
         setLayersFromProject={setLayersFromProject}>
          <img src="./logo.gif" id="logo" />
          <Navbar />
          {isLoggedIn && (
          <MapComponent layersFromProject={layersFromProject}>
             {/* <OrderComponent /> */}
             <AnalysisComponent />
            <StatusBarComponent />
            <MapTools userMail={userMail}/>
          </MapComponent>
        )}
        </AppContextProvider>
        <MapLoader status={false} />
        <PrivacyPolicyModal show={showPrivacyPolicy} onClose={() => { setShowPrivacyPolicy(false); }} />

      </AlertProvider>
      <ToastContainer />
    </>
  );
}

export default App;
