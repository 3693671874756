import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(4), // Ajusta el valor según la cantidad de sangría que desees
    '& ul, & ol': {
        marginLeft: theme.spacing(8), // Doble del padding del contenido
    },
}));


HelpDialog.propTypes = {
    openDialog: PropTypes.bool,
    onClickAction: PropTypes.func
};

export default function HelpDialog(props) {
    const { openDialog, onClickAction } = props;

    const handleClose = () => {
        onClickAction();
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Help Dialog
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <CustomDialogContent dividers>
                    <Typography gutterBottom>
                       <b>Welcome to the Sanzar Group Web Application.</b> 
                    </Typography>
                    <Typography gutterBottom>
                        This platform allows you to analyze various geospatial data layers rendered on the map using advanced artificial intelligence. Depending on the project, you can gain insights into:
                    </Typography>
                    <Typography component="ul" gutterBottom>
                        <li>Tree count within a specified area.</li>
                        <li>Water stress levels.</li>
                        <li>Affected zones and more..</li>
                    </Typography>
                    <Typography gutterBottom>
                        To get started:
                    </Typography>
                    <Typography component="ol" gutterBottom>
                        <li><b>New Project:</b> Click "New Project" to create a new project and upload your geospatial data.</li>
                        <li><b>Projects:</b> Access and manage your existing projects.</li>
                        <li><b>Contact:</b> Reach out to us for any assistance or inquiries.</li>
                    </Typography>
                    <Typography gutterBottom>
                        For detailed guides and support, please visit our main website at <a href="https://www.sanzar-group.com/es" target="_blank" rel="noopener noreferrer">Sanzar Group</a>.
                    </Typography>
                    <Typography gutterBottom>
                        If you need further assistance, feel free to contact us at <a href="mailto:info@sanzar-group.com">info@sanzar-group.com</a>.
                    </Typography>
                    <Typography gutterBottom>
                        Thank you for using Sanzar Group.
                    </Typography>
                </CustomDialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}