/* eslint-disable-next-line  no-unused-vars */
import React, { createElement, useEffect, useRef, useState, useContext } from 'react';
import { AppContext } from "../../context/AppContextProvider";
import PropTypes from 'prop-types';
import './Order.scss';
// import { MapContext } from '../map/MapContextProvider';
// Importing Material-UI components to build the user interface.
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Slider, Tab, Tabs, TextField, Typography } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
//import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useAlert } from "../Alert/useAlert";
//imports para calendario
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange  } from 'react-date-range';
import {KML} from 'ol/format';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import { MapContext } from "../../context/MapContextProvider";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BarChartIcon from '@mui/icons-material/BarChart';
import { isEmpty } from 'ol/extent';
import { Style, Stroke, Fill } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('xs')]: {
        fontSize: '0.5rem',
      },
      [theme.breakpoints.between('xs', 'md')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
  }));

//Style definition
const DemoPaper = styled(Paper)(({ theme }) => ({
    width: 200,
    height: 130,
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
    margin: 'auto',
}));

// Definition of the functional component AnalysisTypeButton to render selection buttons for analysis type.
function AnalysisTypeButton({ type, selectedType, onClick }) {

    return (
        <div className="container-row">
            <Button
                color={selectedType == type ? 'success' : 'primary'}
                onClick={() => onClick(type)} variant="contained" fullWidth>{type}</Button>
        </div>

    );
}

// Main component OrderComponent for the ordering/parameterization interface of analyses.
export default function OrderComponent({ }) {

    const { selectedMenu,setSelectedMenu, isMenuActive,layersFromProject,isActiveTool, setIsActiveTool,userId, toggleProjectsUpdate } = useContext(AppContext);
    const [selectedType, setSelectedType] = useState();

    // const { map } = useContext(MapContext);
    const legendRef = useRef();
    const [resolution, setResolution] = useState('30cm');
    const [type, setType] = useState();
    const [tabValue, setTabValue] = useState(1);
    const [existingAOI, setExistingAOI] = useState([]);
    const [typeOfAnalisys, setTypeOfAnalisys] = React.useState(''); // estado para seleccionar el tipo de análisis
    const typeOfAnalisysOptions = ['Hydric Map', 'Productivity Map', 'Fertilization Map', 'Forest', 'Tree/Fruit Tree', 'Personalized'];
    const [showPersonalizedInput, setShowPersonalizedInput] = React.useState(false);
    const showAlert = useAlert();
    const [aoiName, setAoiName] = useState('');
    const [currentAoiId,setCurrentAoiId]= useState('');
    //variable de prueba
    const [test, setTest] = useState(true);
    const { map, drawSource  } = useContext(MapContext);
    const [lastUpdate, setLastUpdate] = useState(Date.now());
    const currentKmlLayerRef = useRef(null);

    const [projectName, setProjectName] = useState('');
    const [description, setDescription] = useState('');
    const [customAnalysisType, setCustomAnalysisType] = useState('');
    const [startAnalysisDate, setStartAnalysisDate] = useState(new Date().toISOString());
    const [endAnalysisDate, setEndAnalysisDate] = useState(new Date().toISOString());

    // Función para convertir KML a Base64
    const kmlToBase64 = (kml) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' }));
            reader.onloadend = () => {
                // El resultado incluye el prefijo de tipo de datos, lo eliminamos
                const base64Data = reader.result.split(',')[1];
                resolve(base64Data);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    //función para enviar notificación de orden
    const handleMailNotification = async () => {
        const features = drawSource.getFeatures();
        const format = new KML({ extractStyles: true });
        const kml = format.writeFeatures(features, { dataProjection: 'EPSG:4326', featureProjection: map.getView().getProjection() });
    
        try {
            const kmlData = await kmlToBase64(kml);
            const mailData = {
                userId,
                projectName,
                startAnalysisDate,
                endAnalysisDate,
                kmlData
            };
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
            const response = await fetch(`${API_BASE_URL}/sendOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(mailData)
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } catch (error) {
           // console.error('Error sending mail notification:', error);
            showAlert('Failed to send mail notification.', 'error');
        }
    };

    // función para crear un nuevo proyecto
    const handleCreateProject = async () => {
        const creationDate = new Date().toISOString();
        const areaOfInterestId = currentAoiId;

        const projectData = {
            userId,
            projectName,
            description,
            creationDate,
            startAnalysisDate,
            endAnalysisDate,
            areaOfInterestId
        };

        try {
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
            const response = await fetch(`${API_BASE_URL}/api/Projects`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(projectData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
                
            }

            const result = await response.json();
            //console.log('Project created successfully:', result);
            showAlert('Project created successfully!', 'success');
        } catch (error) {
            //console.error('Error creating project:', error);
            showAlert('Failed to create project.', 'error');
        } finally {
            //Esto sirve para actualizar los proyectos mostrados que tiene el usuario. 
            toggleProjectsUpdate();
        }
    };

    const handleDateChange = (item) => {

        setState([item.selection]);

        // Extraer las fechas de inicio y fin directamente desde el evento para mantener la simplicidad
        const { startDate, endDate } = item.selection;

        // Verificar si ambas fechas están presentes y actualizar los estados de fecha ISO
        if (startDate && endDate) {
            setStartAnalysisDate(startDate.toISOString());
            setEndAnalysisDate(endDate.toISOString());
        // console.log('fechainicio y despues final:',startDate);
        // console.log(endDate)
        }
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setTypeOfAnalisys(value);
        setShowPersonalizedInput(value === 'Personalized');

        if (value === 'Personalized') {
            setDescription('');
        }else {
            // Actualizar la descripción con el valor seleccionado si no es 'Personalized'
            setDescription(value);
        }
    };

    const handleCustomAnalysisTypeChange = (event) => {
        setDescription(event.target.value);
    };

    useEffect(() => {
        console.log('Tipo de análisis actualizado:', description);
    }, [description, typeOfAnalisys]);

    //Renderiza las AOI seleccionada
    const viewAOIOnMap = async (areaOfInterestId) => {
        //console.log("areaidseleccionada",areaOfInterestId);
        try {
            // Sustituye con la URL base de tu API si es diferente
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS
            const response = await fetch(`${API_BASE_URL}/api/AreaOfInterests/areaOfInterestId?areaOfInterestId=${areaOfInterestId}`);
            
        
            if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
            
            }
        
            const aoiDetails = await response.json();
            //console.log("areaidseleccionada", aoiDetails);

            // Decodifica el archivo KML codificado en Base64
            const kmlText = atob(aoiDetails.coordinatesFile);
            const format = new KML();
            const features = format.readFeatures(kmlText, {
            dataProjection: 'EPSG:4326',
            featureProjection: map.getView().getProjection(),
            });
        
            // Verifica si se han leído características válidas
            if (!features.length) {
            //console.error('No features were read from the KML - check if the KML is well-formed and has valid geometries');
            showAlert('No features were found in the KML.', 'error');
            return;
            }

            // Define the style for KML features
            const kmlStyle = new Style({
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.8)', // almost black, with slight transparency
                    width: 3,
                    lineDash: [4, 8]  // dashed pattern: 4 pixels filled, 8 pixels empty
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.3)'  // grey with 50% transparency
                })
            });

            features.forEach(feature => feature.setStyle(kmlStyle));

                //si existe una capa lo quita
            if (currentKmlLayerRef) {
                map.removeLayer(currentKmlLayerRef.current);
            }
            // Create a vector source with features
            const kmlSource = new VectorSource({
                features: features, // the KML features
            });

            // Create a vector layer using the source and style
            const newKmlLayer  = new VectorLayer({
                source: kmlSource,
                properties: {
                    displayInLayerSwitcher: false  // Propiedad personalizada para controlar la visibilidad en layer switcher
                }
            });

            currentKmlLayerRef.current = newKmlLayer;
            // Add the vector layer to the map
            map.addLayer(newKmlLayer);

            // Clear existing features from the draw source, if necessary
            drawSource.clear(true);

            // Fit the map view to the extent of the vector layer's features
            const extent = kmlSource.getExtent();
            if (!isEmpty(extent)) {
                map.getView().fit(extent, { padding: [50, 50, 50, 50],maxZoom: 15, duration: 2000 });
            } else {
                //console.error('The extent of the features is empty - cannot fit the map to this extent');
                showAlert('The AOI has no valid geographic data to display.', 'error');
            }
        } catch (error) {
            //console.error('Error fetching AOI details or rendering KML on the map:', error);
            showAlert('Failed to view AOI on the map.', 'error');
        }
        };

    //Guarda la nueva AOI creada.
    const handleSavePolygon = async () => {
        if (!drawSource||drawSource.getFeatures().length === 0) {
            //console.error('Draw source is not initialized.');
            return;
        }
        const features = drawSource.getFeatures();
        const format = new KML({ extractStyles: true });
        const kml = format.writeFeatures(features, { dataProjection: 'EPSG:4326', featureProjection: map.getView().getProjection() });
        //console.log(kml);
    
        const blob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });
        const formData = new FormData();
        formData.append('kmlFile', blob);
        formData.append('UserId', userId); // You need to replace this with the actual user ID
        formData.append('AreaName', aoiName); // Using the state value for the area name
        formData.append('Description', 'NAN');
    
        try {
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
            const response = await fetch(`${API_BASE_URL}/api/areaOfInterests/`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            
            const result = await response.json();
           // console.log('Upload successful', result);
            setCurrentAoiId(result.areaOfInterestId);
           // console.log('current aoiID: ', currentAoiId);
            setLastUpdate(Date.now());
            setTabValue(3);
        } catch (error) {
           // console.error('Error uploading KML:', error);
        }
    };

     // TODO:Pending to create the logic for save the AOI created. 
    const handleSaveAOI = async () => {
        if (aoiName.length=== 0 || drawSource.getFeatures().length === 0) {
            showAlert('Please enter a name for the AOI and ensure it is drawn on the map before proceeding.', 'error');
           // console.log("entro aqui")
            return;
        }
        handleSavePolygon();
    };

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      ]);

    //console.log("Tipo de proyecto seleccionado desde orderComponent:",selectedType);

    useEffect(() => {
        // setShow()
    }, [selectedMenu]);

    // useEffect(()=>{

    //     console.log("layersfromproyect desde OrderCOMPONENT",layersFromProject)
    // },[layersFromProject]);   

    // Handler to change the selected resolution.
    const handleResolutionChange = (event) => {
        setResolution(event.target.value);
    };

    // Function to handle the selection of analysis type.
    const onSelectType = (type) => {
        setSelectedType(type);
      
        if (type === 'New AOI') {
            setTabValue(2);

        } else if (type === 'Existing AOI') {
            setTabValue(4);
        }
    };

    const handleGoBack = () => {
        if(tabValue === 4){
            map.removeLayer(currentKmlLayerRef.current);
            currentKmlLayerRef.current = null;
        }
        setTabValue(tabValue === 4 ? 1 : tabValue - 1);
       
    };

    const handleSendOrder = async ()  =>{

        if (!projectName || !description || !startAnalysisDate || !endAnalysisDate || !currentAoiId) {
            showAlert('Please fill all required fields.', 'error');
            return;
        }

        try {
            await handleCreateProject();  // Llama a la función que crea el proyecto
            showAlert('Your order has been sent and is under review. We will update you promptly.', 'success');
            onCloseMenu();
            setTabValue(1);
            handleMailNotification();
        } catch (error) {
           // console.error('Failed to send order:', error);
            showAlert('Failed to send order. Please try again.', 'error');
        }
    }


    const onCloseMenu = () =>{
        
        //limpia el mapa de las AOI mostradas.
        if (currentKmlLayerRef.current) {
            map.removeLayer(currentKmlLayerRef.current);
            currentKmlLayerRef.current = null;
        }
        setSelectedMenu(null);    
    }

  
 
    //useEffect mostrar de forma actualizada las AOI. 
    useEffect(() => {
        const fetchAOI = async () => {
            try {
                const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
                //const response = await fetch(`${API_BASE_URL}/api/areaOfInterests/userId?userId=${selectedProjectId}`);
                const response = await fetch(`${API_BASE_URL}/api/areaOfInterests/userId?userId=${userId}`);
                if (response.ok) {
                    const layers = await response.json();
                    //console.log("capas obtenidas dentro de useEffect:", layers);
                    setExistingAOI(layers)
                    //console.log("capas dentro de layersfromproject:", existingAOI);
                } else {
                    //console.error("Failed to fetch layers for project");
                    // Aquí puedes manejar el error, si es necesario
                }
            } catch (error) {
     
               // console.error("Error fetching data from DB:", error);
            }
        };
        if (test) {
            fetchAOI();
        }
    }, [lastUpdate]);

    useEffect(() => {
        if (tabValue === 2) {  // Assuming '2' corresponds to the 'New AOI' tab
          setIsActiveTool('draw');
        } else {
          setIsActiveTool(null);
        }
    }, [tabValue]);  

    // Rendering the component, using TabContext, TabList, and TabPanel to manage different form views.
    return (
        <>
            <div className="container-window" ref={legendRef}
                style={{
                    display: selectedMenu == "order" ? "block" : "none",
                    left: isMenuActive ? "255px" : "85px"
                }}>
                <div className="container-caption">
                    <div>
                        {tabValue === 1 && "Create a New Project"}
                        {tabValue === 2 && "Draw an AOI"}                 
                        {tabValue === 3 && "Select the type of analysis"}
                        {tabValue === 4 && "Existing AOI List"}
                    </div>
                    <div onClick={onCloseMenu}>
                        <CancelIcon/>
                    </div>

                </div>

                <TabContext className="tab-context" value={tabValue}>
                    <div className="contenedor-card">
              
                        {/*-----MENU Principal----*/} 
                        <TabPanel value={1}>

                            <AnalysisTypeButton type='New AOI' selectedType={selectedType} onClick={onSelectType} />
                            <AnalysisTypeButton type='Existing AOI' selectedType={selectedType} onClick={onSelectType} />

                        </TabPanel>
                        
                        {/*    </TabPanel>*/}
                        {/*-----MENU drawAOI----*/} 
                        <TabPanel value={2} >
                            <div className="goBackButtonContainer">
                                <IconButton onClick={handleGoBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            <div className="aoi-actions">
                                <Typography variant="body1" style={{ color: "#000" }} gutterBottom>
                                    Draw your area of interest on the map using the map tools or alternatively place a KML file on the map.
                                </Typography>
                                <TextField
                                    id="name-of-aoi"
                                    label="Name of AOI"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={aoiName}
                                    onChange={(e) => setAoiName(e.target.value)}
                                />
                            </div>
                            <div className="buttonContainer">
                                <Button variant="contained" color="success" onClick={handleSaveAOI}>
                                    Save
                                </Button>
                            </div>
                        </TabPanel>

                        {/*-----MENU Type of analysis from new AOI----*/}
                        <TabPanel value={3}>
                            <div className="goBackButtonContainer">
                                <IconButton onClick={handleGoBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            
                            <div className="aoi-actions">
                                    <TextField
                                        id="name-of-aoi"
                                        label="Name of Project"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        onChange={e => setProjectName(e.target.value)}
                                    />
                            </div>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Type of Analysis</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeOfAnalisys}
                                    label="Type of Analysis"
                                    onChange={handleSelectChange}
                                >
                                    {typeOfAnalisysOptions.map((element, index) => (
                                    <MenuItem key={index} value={element}>{element}</MenuItem>
                                    ))}
                                </Select>
                                    {showPersonalizedInput && (
                                    <TextField
                                    id="name-of-aoi"
                                    label="Type of analysis personalized"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    onChange={handleCustomAnalysisTypeChange} // Use this handler here
                                    value={description}
                                    />
                                )}                       
                            </FormControl>
                            <div style={{ marginTop: "16px", display: "flex", justifyContent: "center", scale: 0.9,transform: "scale(0.9)" }}>
                                <DateRange
                                editableDateInputs={true}
                                onChange={handleDateChange}
                                moveRangeOnFirstSelection={false}
                                showDateDisplay ={false}
                                //minDate={new Date()} 
                                ranges={state}
                                />
                            </div>
                        
                            <div className="buttonContainer">
                                <Button variant="contained" color="success" onClick={handleSendOrder}>
                                        Send order
                                </Button>
                            </div>                           
                        </TabPanel>

                        {/*-----MENU Existin AOI List----*/} 
                        <TabPanel value={4}>
                            <div className="goBackButtonContainer">
                                <IconButton onClick={handleGoBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            {existingAOI.length > 0 ? (
                                    existingAOI.map((AOI) => (
                                        <Card key={AOI.areaOfInterestId} sx={{ maxWidth: 350, marginBottom: '16px' }}>             
                                            <CardHeader 
                                                title={AOI.areaName} 
                                                subheader={new Date(AOI.creationDate).toLocaleDateString()} 
                                            />                                           
                                            <CardContent>
                                            </CardContent>
                                            
                                            <CardActions disableSpacing>
                                            <IconButton aria-label="add to favorites" onClick={() => viewAOIOnMap(AOI.areaOfInterestId)}>
                                                <VisibilityIcon />
                                                </IconButton>
                                                <ExpandMore
                                                    expand={false} // Asegúrate de manejar este estado según sea necesario
                                                    aria-label="show more"
                                                    onClick={() => {setTabValue(5); setCurrentAoiId(AOI.areaOfInterestId)}}
                                                >
                                                    <BarChartIcon /> <CustomTypography>Select Analysis</CustomTypography>
                                                </ExpandMore>
                                            </CardActions>
                                        </Card>
                                    ))
                                ) : (
                                    <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: '20px', color: "#000"  }}>
                                        Currently, there are no AOI associated with your account.
                                    </Typography>
                            )}
                        </TabPanel>
                  

                        {/*-----MENU Type of analysis from existing AOI----*/}
                        <TabPanel value={5}>
                        <div className="goBackButtonContainer">
                                <IconButton onClick={handleGoBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            
                            <div className="aoi-actions">
                                    <TextField
                                        id="name-of-aoi"
                                        label="Name of Project"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        onChange={e => setProjectName(e.target.value)}
                                    />
                            </div>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Type of Analysis</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeOfAnalisys}
                                    label="Type of Analysis"
                                    onChange={handleSelectChange}
                                >
                                    {typeOfAnalisysOptions.map((element, index) => (
                                    <MenuItem key={index} value={element}>{element}</MenuItem>
                                    ))}
                                </Select>
                                    {showPersonalizedInput && (
                                    <TextField
                                    id="name-of-aoi"
                                    label="Type of analysis personalized"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    onChange={handleCustomAnalysisTypeChange} // Use this handler here
                                    value={description}
                                    />
                                )}                       
                            </FormControl>
                            <div style={{ marginTop: "16px", display: "flex", justifyContent: "center", scale: 0.9,transform: "scale(0.9)" }}>
                                <DateRange
                                editableDateInputs={true}
                                 onChange={handleDateChange}
                                moveRangeOnFirstSelection={false}
                                showDateDisplay ={false}
                                //minDate={new Date()} 
                                ranges={state}
                                />
                            </div>
                            
                            <div className="buttonContainer">
                                <Button variant="contained" color="success" onClick={handleSendOrder}>
                                        Send order
                                </Button>
                            </div>
                            
                        </TabPanel>
                    </div>
                </TabContext >
                  
            </div >
        </>
    );
}
