import axios from "axios";
const baseURL = "http://localhost:4000"
const token = "YWRtaW46ITIzNDU2Nzg=";

//export function getAccessToken() {
//    var config = {
//        'method': 'GET',
//        'url': 'http://localhost:4000/login',
//        'headers': {
//        }
//      };

//    return axios(config)
//        .then(function (response) {

//            return response.data;
//        })
//        .catch(function (error) {
//            console.log(error);
//        });
//}

//export function getObjectList() {
//    var config = {
//        'method': 'GET',
//        'url': 'http://localhost:4000/balance',
//        'headers': {
//        }
//      };
//    return axios(config)
//        .then(function (response) {
//            // console.log(JSON.stringify(response.data));
//            console.log(response);
//            return response.data;
//        })
//        .catch(function (error) {
//            console.log(error);
//        });
//}

// Creaci�n de una instancia personalizada de Axios con una URL base y encabezados comunes
const axiosInstance = axios.create({
    baseURL,
    headers: {
        Authorization: `Bearer ${token}`
    }
});

// Funci�n para obtener el token de acceso
export function getAccessToken() {
    return axiosInstance.get("/login")
        .then(response => response.data)
        .catch(error => {
            console.error("Error fetching access token:", error);
            throw error; // Propagar el error para que sea manejado externamente
        });
}

// Funci�n para obtener la lista de objetos
export function getObjectList() {
    return axiosInstance.get("/balance")
        .then(response => {
            console.log(response);
            return response.data;
        })
        .catch(error => {
            console.error("Error fetching object list:", error);
            throw error; // Propagar el error para que sea manejado externamente
        });
}