import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContextProvider";
import { MapContext } from "../../context/MapContextProvider";
import './DrawingTool.scss';
import {
    Divider,
    IconButton,
    Tooltip
} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { 
    DeleteOutline,
    // ModeEditOutlineOutlined, PolylineOutlined, SaveOutlined, StraightenOutlined, HighlightAlt 
}from "@mui/icons-material";
import {
    Circle as CircleStyle,
    Fill,
    RegularShape,
    Stroke,
    Style,
    Text,
} from 'ol/style.js';

import MultiPoint from 'ol/geom/MultiPoint.js';
import { Draw, Modify } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { getArea, getLength } from 'ol/sphere.js';
import { LineString, Point } from 'ol/geom.js';
import CropIcon from '@mui/icons-material/Crop';
//import Select from 'ol/interaction/Select'; 
import {KML} from 'ol/format';


//Style definitions. 
const editStyles = [
    /* We are using two different styles for the polygons:
     *  - The first style is for the polygons themselves.
     *  - The second style is to draw the vertices of the polygons.
     *    In a custom `geometry` function the vertices of a polygon are
     *    returned as `MultiPoint` geometry, which will be used to render
     *    the style.
     */
    new Style({
        stroke: new Stroke({
            color: 'blue',
            width: 3,
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
        }),
    }),
    new Style({
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({
                color: 'orange',
            }),
        }),
        geometry: function (feature) {
            // return the coordinates of the first ring of the polygon
            const coordinates = feature.getGeometry().getCoordinates()[0];
            return new MultiPoint(coordinates);
        },
    }),
];

const style = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2,
    }),
    image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
    }),
});

const labelStyle = new Style({
    text: new Text({
        font: '14px Calibri,sans-serif',
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        padding: [3, 3, 3, 3],
        textBaseline: 'bottom',
        offsetY: -15,
    }),
    image: new RegularShape({
        radius: 8,
        points: 3,
        angle: Math.PI,
        displacement: [0, 10],
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
    }),
});

const tipStyle = new Style({
    text: new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
        padding: [2, 2, 2, 2],
        textAlign: 'left',
        offsetX: 15,
    }),
});

const modifyStyle = new Style({
    image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
    }),
    text: new Text({
        text: 'Drag to modify',
        font: '12px Calibri,sans-serif',
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        padding: [2, 2, 2, 2],
        textAlign: 'left',
        offsetX: 15,
    }),
});

const segmentStyle = new Style({
    text: new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
        padding: [2, 2, 2, 2],
        textBaseline: 'bottom',
        offsetY: -12,
    }),
    image: new RegularShape({
        radius: 6,
        points: 3,
        angle: Math.PI,
        displacement: [0, 8],
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
    }),
});//END STYLE DEFINITIONS

const formatLength = function (line) {
    const length = getLength(line);
    let output;
    if (length > 1000) {
        // output = Math.round((length / 1000) * 100) / 100 + ' km';
        output = (((length / 1000) * 100) / 100).toFixed(4) + ' km';;

    } else {
        // output = Math.round(length * 100) / 100 + ' m';
        output = ((length * 100) / 100).toFixed(4) + ' m';
    }
    return output;
};

const formatArea = function (polygon) {
    const area = getArea(polygon);
    let km = (((area / 1000000) * 100) / 100).toFixed(3);
    let output;
    if (area > 10000) {
        output = km + ' km\xB2';
    } else {
        let meter = ((area * 100) / 100).toFixed(3)
        output = + meter + ' m\xB2';
    }
    output += '   ' + (km * kmPrice).toFixed(3) + '$'
    return output;
};


const kmPrice = 10;
const segmentStyles = [segmentStyle];
var tipPoint, modify;

// const inactiveIconUrl = process.env.PUBLIC_URL + 'icons/area.png';
const inactiveIconUrl = process.env.PUBLIC_URL + 'logoArea.svg';
const activeIconUrl = process.env.PUBLIC_URL + '/icons/areaActive.png';

const DrawComponent = () => {
    const { map, userId, drawSource,setDrawSource } = useContext(MapContext);
    const [deletedModelItem, setDeletedModelItem] = useState('');
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [kmlLoader, setKMLLoader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [isDrawingActive, setIsDrawingActive] = useState(false);
    const [isModifyActive, setIsModifyActive] = useState(false);
    const [drawAction, setDrawAction] = useState();
    const [currentAction, setCurrentAction] = useState("");
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [activeDrawingType, setActiveDrawingType] = useState("");
    const { selectedMenu, setSelectedMenu, isMenuActive } = useContext(AppContext);

    
    useEffect(() => {
        //Load KML from local stroage
        // Initialize the Vector Source, Modify interaction, Vector Layer, etc.
        const source = new VectorSource();

        setDrawSource(source);
        modify = new Modify({ source: source, style: modifyStyle });

        // Adding Vector Layer and Modify Interaction to the map
        const vector = new VectorLayer({
            source: source,
            style: function (feature) {
                //todo show / hide segments here
                return styleFunction(feature, true);
            },
            zIndex: 1000,
            title: "Draw Projects"
        });
        map.addLayer(vector)
        map.addInteraction(modify);

        // Cleanup function
        return () => {

            if (map) {
                // Remove all custom interactions added to the map
                map.getInteractions().forEach(interaction => {
                    if (interaction instanceof Draw || interaction instanceof Modify /* or other types you've added */) {
                        map.removeInteraction(interaction);
                    }
                });

                // Remove all custom layers added to the map
                map.getLayers().getArray().slice().forEach(layer => {
                    if (layer.get('title') === 'Draw Projects' /* or check other properties as needed */) {
                        map.removeLayer(layer);
                    }
                });

            }
        }

    }, [map, selectedMenu]);//End UseEffect()


    const uploadKMLHandler = () => {
        console.log("KML Loader Here")
        setKMLLoader(true);
    };

    function addInteraction(source, drawType = "Polygon") {
        if (drawAction) {
            map.removeInteraction(drawAction);
        }

        if (activeDrawingType == drawType)
            setActiveDrawingType("");
        else
            setActiveDrawingType(drawType);

        const activeTip =
            'Click to continue drawing the ' +
            (drawType === 'Polygon' ? 'polygon' : 'line');
        const idleTip = 'Click to start measuring';
        let tip = idleTip;
        let draw = new Draw({
            source: source,
            type: drawType,
            style: function (feature) {
                return styleFunction(feature, true, drawType, tip);
            },
        });
        draw.on('drawstart', function () {

            modify.setActive(false);
            tip = activeTip;
        });
        
        draw.on('drawend', function () {
            modifyStyle.setGeometry(tipPoint);
            modify.setActive(true);
            map.once('pointermove', function () {
                modifyStyle.setGeometry();
            });
            tip = idleTip;
        });
        setDrawAction(draw);
        map.addInteraction(draw);
    }



    function styleFunction(feature, segments, drawType, tip) {
        const styles = [];
        const geometry = feature.getGeometry();
        const type = geometry.getType();
        let point, label, line;
        if (!drawType || drawType === type || type === 'Point') {
            styles.push(style);
            if (type === 'Polygon') {
                point = geometry.getInteriorPoint();
                label = formatArea(geometry);
                line = new LineString(geometry.getCoordinates()[0]);
            } else if (type === 'LineString') {
                point = new Point(geometry.getLastCoordinate());
                label = formatLength(geometry);
                line = geometry;
            }
        }
        if (segments && line) {
            let count = 0;
            line.forEachSegment(function (a, b) {
                const segment = new LineString([a, b]);
                const label = formatLength(segment);
                if (segmentStyles.length - 1 < count) {
                    segmentStyles.push(segmentStyle.clone());
                }
                const segmentPoint = new Point(segment.getCoordinateAt(0.5));
                segmentStyles[count].setGeometry(segmentPoint);
                segmentStyles[count].getText().setText(label);
                styles.push(segmentStyles[count]);
                count++;
            });
        }
        if (label) {
            labelStyle.setGeometry(point);
            labelStyle.getText().setText(label);
            styles.push(labelStyle);
        }
        if (
            tip &&
            type === 'Point' &&
            !modify.getOverlay().getSource().getFeatures().length
        ) {
            tipPoint = geometry;
            tipStyle.getText().setText(tip);
            styles.push(tipStyle);
        }
        return styles;
    }

    const drawProjectArea = (type) => {
        if (currentAction === type) {
            // If the requested type is already active, deactivate it
            if (drawAction) {
                map.removeInteraction(drawAction);
                setDrawAction(null); // Clear the current draw action
            }
            setCurrentAction(null); // No interaction is currently active
        } else {
            // If the requested type is not already active, activate it
            // First, deactivate any currently active interaction
            if (drawAction) {
                map.removeInteraction(drawAction);
                setDrawAction(null); // Clear the current draw action
            }
            // Then, add the new interaction for the requested type
            addInteraction(drawSource, type);
            setCurrentAction(type); // Set the new active interaction type
        }
    };
    // Function to determine style based on active tool
    const getToolStyle = (toolType) => {
        return { color: currentAction === toolType ? "#e57373" : "#fff" };
    };



    const clearDrawing = () => {
        // Check if drawSource is initialized
        try {
            if (drawSource) {
                drawSource.clear(); // Clears all features from the vector source
                console.log("All drawings have been cleared from the map.");
            }
        } catch (e) {
            console.erro("An error occurred while trying to clear drawings:", e);
        }
      
    };

    return (
        <div className="tools">

            <div className="tool-item">
                <Tooltip title="Draw Project Area" arrow>
                    {/* Using an img tag inside a button for the custom icon */}
                    <IconButton onClick={() => drawProjectArea("Polygon")}>
                    <CropIcon style={{ color: currentAction === "Polygon" ? "#e57373" : "#fff" }} />
                </IconButton>
                </Tooltip>
            </div>

            {/* <div className="tool-item">
                <Divider component="menu" />
                <Tooltip title="Upload KML Model" arrow>
                    <FileUploadOutlinedIcon onClick={uploadKMLHandler} />
                </Tooltip>
            </div> */}

            <div className="tool-item">

                <Tooltip title="Delete Polygons" arrow>
                    <DeleteOutline onClick={clearDrawing} />
                </Tooltip>
            </div>

        </div>
    );
}

export default DrawComponent;